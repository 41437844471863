.menu-container {
    position: fixed;
    z-index: 18;
    top: 0;
    right: 0;
    bottom: 0;
    width: 400px;
    background: var(--color-body98);
    margin-top: 10vh;
    height: 90vh;
    transform: translateX(-100%);
    will-change: transform;
    -webkit-font-smoothing: antialiased;

    transition: background-color 0.25s ease-out;
}

.menu-ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
}

.menu-option {
    color: var(--color-text);
    display: block;
    transform-origin: calc(40vw - 20px) 50%;
    font-weight: bold;
    font-size: 48px;
    padding: 10px;
    will-change: transform, opacity, filter;
    text-decoration: none;

    transition: all;
}

.menu-option:hover{
    color: var(--color-contrast3dark);
}

.menu-ul,
.menu-option {
    list-style: none;
    margin: 0;
}

.navbar-button-container {
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    top: 10vh;
    width: 100%;
    height: 0;
    display: flex;
    justify-content: end;
    align-items: center;
}

.navbar-button {
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--color-body);
    padding: 10px;
    transform: translateY(-5vh);
}

.navbar-button path {
    fill: var(--color-text);
}

@media screen and (max-width: 700px) {
    .menu-container {
        width: 100%;
    }
}
