#NavContainer {
    /* Positioning */
    position: fixed;
    z-index: 19;
    top: 0;

    /* Display & Box Model | Sizing */
    width: 100%;
    height: 100%;

    display: grid;
    align-items: center;

    /* Color, Background & Text */
    background: var(--color-body80);
    font-family: sans-serif;

    /* Animations and Other */
    /* transition: 0.2s ease-in-out; */
    /* top: 0; */
    backdrop-filter: blur(10px);
}

.NavMenu {
    /* Positioning */

    /* Display & Box Model | Sizing */
    display: grid;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 100px);

    /* Color, Background & Text */

    /* Animations and Other */
    transition: all 0.3s;
}

.navOption {
    /* Positioning */

    /* Display & Box Model | Sizing */
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    /* Color, Background & Text */
    color: var(--color-text);
    font-size: 3rem;
    text-decoration: none;
    list-style: none;
    text-decoration: none;

    /* Animations and Other */
    transition: 0.2s ease-in-out;
    cursor: pointer;
}
.navOption:hover{
    color: var(--color-contrast2);
    transition: all 0.2s;
    filter: drop-shadow(0 0 1rem var(--color-body));
    filter: drop-shadow(0 0 0 1rem var(--color-body));
}

/***---PHONE SCREENS---***/
@media screen and (max-width: 700px){
    #NavContainer {
        width: 100%;
        height: 100vh;
        background: rgba(36, 39, 43, 0.95);
        /* overflow: hidden; */
        /* position: -webkit-sticky; // makes the header be always atop no matter if you scroll
        position: sticky; */
    }
    .NavMenu {
        grid-template-rows: repeat(5,80px);
    }
}