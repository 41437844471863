.App {
  text-align: center;
  font-family: sans-serif, monospace
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/***---COMPONENTS---***/

/*---Container---*/
.Container {
  /* Positioning */
  position: relative;
  z-index: 10;

  /* Display & Box Model | Sizing */
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Color, Background & Text */
  background-color: transparent;
  color: var(--color-text);

  /* Animations and Other */
}

.block {
  display: block;
}
.inline-block {
  display: inline-block;
}

.noisey-overlay-container{
  position: absolute;
  top: 0;
  z-index: 21;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

.noisey-overlay-effect {
  position: fixed;
  z-index: 21;
  inset: -200%;
  /* background-color: red; */
  background-image: url("./components/imgs/white-noise-png-2.png");
  opacity: 2%;

  animation: shift 0.2s linear infinite both;
}

@keyframes shift {
  0% {
    transform: translateX(10%) translateY(10%);
  }
  100% {
    transform: translateX(-10%) translateY(-10%);
  }
  
}

/***---PHONE SCREENS---***/
@media screen and (max-width: 700px){
  .Container {
    width: 100vw;
    justify-content: space-between;
  }

  #NavContainer {
    width: 100%;
    height: 100vh;
    background: var(--color98);
    overflow: hidden;
  }
}
@media screen and (max-height: 540px){
  .Container {
    height: 70px;
  }
  .NavMenu {
    grid-template-rows: repeat(5,80px);
  }
  .TextP {
    font-size: 1rem;
  }
}