.background-blobs-container {
    /* Positioning */
    position: fixed;
    top: 0;
    z-index: 1;

    /* Display & Box Model | Sizing */
    width: 100%;
    height: 100vh;

    /* Color, Background & Text */
    background: var(--color-background);

    /* Animations and Other */
}

.background-blobs-container svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.background-blur-effect {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(30px);
}


#blob1-gradient1 {
    stop-color: var(--color-contrast3);
}

#blob1-gradient2 {
    stop-color: var(--color-contrast);
}

#blob2-gradient1 {
    stop-color: var(--color-contrast2);
}

#blob2-gradient2 {
    stop-color: var(--color-contrast2darker);
}

#blob3-gradient1 {
    stop-color: var(--color-contrast3);
}

#blob3-gradient2 {
    stop-color: var(--color-contrast2light);
}

#blob4-svg {
    z-index: 2;
}

#blob4-gradient1 {
    stop-color: var(--color-contrastlighter);
}

#blob4-gradient2 {
    stop-color: var(--color-contrastdark);
}

#blob5-gradient1 {
    stop-color: var(--color-contrast2light);
}

#blob5-gradient2 {
    stop-color: var(--color-contrastdark);
}

#blob6-svg {
    z-index: 1;
}

#blob6-gradient1 {
    stop-color: var(--color-contrastlight);
}

#blob6-gradient2 {
    stop-color: var(--color-contrast3);
}

#blob7-gradient1 {
    stop-color: var(--color-contrastlighter);
}

#blob7-gradient2 {
    stop-color: var(--color-contrastlight);
}

#blob8-gradient1 {
    stop-color: var(--color-contrast3);
}

#blob8-gradient2 {
    stop-color: var(--color-contrast2light);
}

@media screen and (max-width: 700px) {
    .background-blobs-container {
        width: 100%;
        height: 100lvh;
    }
}